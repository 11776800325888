.admin-sidebar {
  position: fixed;
  overflow-y: scroll;
  width: 220px;
  left: 0;
  height: 100%;
  top: 0px;
  z-index: 10;
  padding-bottom: 30px;
  box-shadow: 1px 0px 20px rgba(0, 0, 0, 0.08);
  transition: all 0.35s ease-in-out;

  .nav-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    user-select: none;
  }

  /* width */
  &::-webkit-scrollbar {
    width: 0px;
  }

  .logo {
    background-color: #fff;
    display: block;
    margin: 0 auto 20px auto;
    width: 100%;
  }

  .sub-menu {
    background: #141619;
    display: none;
    a {
      padding-left: 50px;
    }
  }

  .nav-item {
    &:hover,
    &.active {
      background-color: #00000038;
    }
  }

  .pages-nav {
    background: #00000038;
  }

  .nav-link {
    color: #fff;
    padding: 14px 15px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    white-space: nowrap;
    align-items: center;
    line-height: 25px;
    opacity: 1;
    &:hover {
      cursor: pointer;
    }

    h6 {
      color: #fff;
      margin-bottom: 0;
    }

    .icon-class-name {
      margin-right: 7px;
      font-size: 20px;
      color: coral;
    }

    &:hover {
      opacity: 1;
    }

    &.active {
      background: #27a9e3;
      color: #fff;
      opacity: 1;
    }
  }
}
