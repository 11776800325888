@media (max-width: 991px) {
  .admin-layout {
    .admin-sidebar {
      left: -290px;
    }

    .admin-wrapper {
      margin-left: 0;
    }
  }
}

@media (max-width: 900px) {
  table {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
}
