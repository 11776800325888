@import "responsive";
@font-face {
  font-family: droid;
  src: url("../fonts/droid.ttf");
}
@font-face {
  font-family: "droid-bold";
  src: url("../fonts/droid-bold.ttf");
}
@import "~bootstrap/dist/css/bootstrap.css";

body {
  &::-webkit-scrollbar {
    width: 0px;
  }
}

input,
select {
  height: 40px !important;
}

th,
td {
  text-align: center !important;
}

.en-font {
  font-family: Arial, Helvetica, sans-serif !important;
}

.line-through {
  text-decoration: line-through;
  color: #9e9e9e;
}

.ar-font-bold {
  font-family: "droid-bold";
}

.ant-picker-range {
  direction: ltr;
}

.ant-modal-content {
  text-align: right;
  font-family: "droid";
  direction: rtl;
}

.ant-modal-close {
  left: 0px;
  right: auto;
}

.ant-table-pagination.ant-pagination {
  direction: ltr;
}
/* .ant-select-selector {
   height: 40px;
} */
.ant-select-multiple .ant-select-selection-item-content {
  margin-left: 8px;
  margin-right: 8px;
}
.plan-color {
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin: auto;
  display: block;
}

.logo-container {
  margin: 10px;
  padding: 10px;
  background-color: #fff;
  border-radius: 5px;
}

.dir-ar {
  direction: rtl;
  text-align: right;
  font-family: droid;

  .admin-wrapper {
    margin-left: 0px;
    margin-right: 220px;
  }

  .admin-title {
    h6 {
      font-family: "droid-bold";
    }
  }
  .admin-sidebar {
    left: auto;
    right: 0;

    .nav {
      padding-right: 0px;
      .nav-link .icon-class-name {
        margin-right: 0px;
        margin-left: 7px;
      }
    }
  }
}

.statics-numbers {
  font-size: 70;
  font-weight: bold;
  margin-bottom: 7;
}

.cover-certificate {
  width: 100%;
  overflow: hidden;
  height: 200px;
  border: 1px solid #ccc;

  img {
    height: 100%;
    display: block;
    margin: auto;
  }
}
.avatar {
  position: relative;
  width: 150px;
  height: 150px;
  display: block;
  margin: auto;
}
.remove-avatar {
  bottom: 8px;
  position: absolute;
  left: 56px;
  background: red;
  color: #fff;
  border: none;
}

.avatar-place {
  width: 150px;
  height: 150px;
  display: block;
  margin: auto;
  border-radius: 50%;
  background-color: #d0d0d0;
  overflow: hidden;
  margin-bottom: 30px;
  border: 3px solid #e0e0e0;

  img {
    width: 100%;
    display: block;
    margin: auto;
  }
}

.remove-time {
  width: 25px;
  height: 25px;
  background: red;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: absolute;
  left: 29px;
  top: 30px;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.remove-surat {
  width: 25px;
  height: 25px;
  background: red;
  border-radius: 50%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: none;
}

.remove-certi {
  left: 24px;
  top: 14px;
}

.remove-item {
  border: 1px solid #ccc;
  color: red;
}

.label-show {
  background: #f1f0f0;
  padding: 8px;
  font-size: 19px;
  margin-top: 6px;
  margin-bottom: 6px;
  border-radius: 3px;
}

.required {
  color: red;
}

.main-color {
  color: #2dae60 !important;
}
.main-bg {
  background-color: #2dae60;
}

.btn-primary {
  background-color: #2dae60;
  border-color: #2dae60;

  &:hover {
    background-color: #289452;
    border-color: #289452;
  }
}

.login-logo {
  width: 300px;
  display: block;
  margin: 0px auto 20px;
}

.input-sort {
  width: 50px;
  border: 1px solid #d0d0d0;
  border-radius: 5px;
}

.btn-actions-prod button {
  margin-right: 20px;
}

.filter-sorted {
  background: #ececec;
  height: auto;
  padding: 12px;
  margin-bottom: 20px;

  &:hover {
    cursor: move;
  }
}

.upload-new {
  border-radius: 3px;
  border: 1px solid #ccc;
  height: 200px;
  width: 200px;
  margin-bottom: 20px;
  background-color: #f6f6f6;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 38px;
}

.custom-btn {
  align-items: center;
  display: inline-flex;
  height: 28px;
}
.details-vendor {
  position: relative;

  .full-details {
    position: absolute;
    top: 0;
    z-index: 9;
    background: #fff;
    padding: 20px;
    border-radius: 11px;
    display: block;
    margin: auto;
    left: 0;
    right: 0;
    width: 70%;
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.2);

    .seperaror {
      width: 100%;
      height: 1px;
      background: #ccc;
      margin-bottom: 20px;
    }

    img {
      background: #ccc;
      width: 100%;
      height: auto;
    }
  }

  .close-details {
    background: #ccc;
    width: 25px;
    height: 25px;
    color: #fff;
    border-radius: 50%;
    text-align: center;
    position: absolute;
    right: 20px;

    &:hover {
      cursor: pointer;
    }
  }

  .overlay {
    position: fixed;
    top: 0;
    left: 0;
    background: #000;
    width: 100%;
    height: 100%;
    opacity: 0.4;
    z-index: 8;
  }
}

.remove-region {
  width: 25px;
  height: 25px;
  background: rgb(255, 1, 1);
  color: rgb(255, 255, 255);
  border-radius: 50%;
  text-align: center;

  &:hover {
    cursor: pointer;
  }
}

.remove-rate {
  background: #ff0101;
  color: #fff;
  text-align: center;
  border: none;

  &:hover {
    cursor: pointer;
  }
}

.required-star {
  margin-right: 4px;
}

.ant-select-selection {
  height: 38px;
}
.ant-select-selection--multiple {
  height: auto;
}

.ant-select-selection-selected-value {
  padding-top: 2px;
  color: #292929;
  font-size: 16px;
}

.btn-sm {
  font-size: 11px;
  padding: 3px 6px;
  margin-right: 4px;
}

.form-button {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.ant-message-custom-content {
  display: flex;
  align-items: center;
}
.form-button .anticon-loading {
  margin-right: 5px;
  display: inline-flex;
  align-items: center;
}

.admin-title-super2 {
  display: flex;
}

.label-header {
  display: flex;
  align-items: center;
  margin-bottom: 8px;
}
.custom-label {
  color: #000;
  margin-bottom: 0;
  display: block;
}

/* tags */
.tags-container {
  display: flex;
  flex-wrap: wrap;
  height: auto;
  align-items: center;

  .ant-tag {
    margin-bottom: 10px;
  }

  input {
    width: 150px;
  }
}

.break-names {
  width: 115px;
  word-break: break-word;
}

/* text editor */
.rdw-emoji-modal,
.rdw-embedded-modal,
.rdw-link-modal,
.rdw-image-modal {
  right: 5px !important;
  left: auto !important;
  min-height: 240px !important;
}
.rdw-editor-wrapper {
  border: 1px solid #eaeaea;
}

.lg-button {
  width: 400px;
  display: block;
  margin: auto;
  margin-top: 30px;
}

.public-DraftStyleDefault-block {
  padding: 0 20px;
}

.attr-container {
  display: flex;
  width: 180px;
  flex-wrap: wrap;

  .attr-tag {
    background: #8dbef3;
    padding: 5px;
    color: #fff;
    border-radius: 2px;
    margin-right: 5px;
    margin-bottom: 5px;
  }
}

.adminLogin {
  background-color: #f1f1f1;
  height: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  .login-form {
    background: #fff;
    padding: 47px;
    width: 400px;
    display: block;
    margin: auto;
    text-align: right;

    label {
      text-align: right;
      font-weight: bold;
      font-size: 1.2rem;
    }

    .submit-btn {
      width: 100%;
      height: 45px;
      border: none;
      color: #fff;
      font-weight: bold;
      font-size: 1.2rem;
    }

    .kimo-logo {
      display: block;
      margin: auto;
    }
  }
}

.admin-layout {
  padding-bottom: 30px;
  background: #f1f1f1;
  min-height: 100vh;

  &.scenario1 {
    #admin-sidebar {
      width: 85px;

      .nav-link {
        h6 {
          display: none;
        }
      }
    }

    .admin-wrapper {
      margin-left: 0;
    }
  }

  &.scenario2 {
    #admin-sidebar {
      left: 0px;
    }

    .admin-wrapper {
      margin-left: 0;
    }
  }
}

.admin-wrapper {
  margin-left: 200px;
  padding-top: 40px;
  transition: all 0.35s ease-in-out;
  -webkit-transition: all 0.35s ease-in-out;
  -moz-transition: all 0.35s ease-in-out;
  -o-transition: all 0.35s ease-in-out;

  .index-actions {
    .btn {
      margin-right: 5px;
    }
  }

  .no-move {
    &:hover {
      cursor: auto !important;
    }
  }

  .image-wrapper-gallary {
    position: relative;
  }
  .remove-image {
    opacity: 1;
    position: absolute;
    border: none;
    top: 0px;
    right: 35px;
    width: 20px;
    padding-bottom: 3px;
    height: 20px;
    z-index: 9999;
    color: #fff;
    background: #ff4242;
    text-align: center;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      cursor: pointer;
    }
  }

  td {
    vertical-align: middle;
    display: table-cell;
  }

  .table-img {
    width: auto;
    height: 70px;
  }
  .table-img-cover {
    width: 150px;
    height: 80px;
  }

  .image-preview {
    position: relative;
    border-radius: 3px;
    border: 1px solid #ccc;
    padding: 10px;
    height: 200px;
    width: 200px;
    overflow: hidden;
    margin-bottom: 20px;
    margin-right: 20px;
    position: relative;

    &.single {
      width: auto;
      height: 240px;
    }

    &.product-one {
      width: auto;
    }

    &:hover {
      cursor: move;
      .remove-image {
        opacity: 1;
      }
    }

    .name-preview {
      background-color: #ffffff63;
      text-align: center;
      position: absolute;
      bottom: 27px;
      display: block;
      right: 0;
      color: #000;
      left: 0;
      padding-top: 8px;
      margin: auto;
      align-items: center;
      justify-content: center;
      height: 36px;
      border-radius: 30px;
      width: 75%;
    }

    img {
      width: 100%;
    }
  }

  .admin-title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;

    .admin-title-super {
      display: flex;
      width: 50%;

      .admin-title-search {
        width: 70%;
        margin-right: 20px;
      }
    }
  }

  .bg-color1 {
    background: #673ab7 !important;
  }

  .bg-color2 {
    background: #ffc107 !important;
  }

  .bg-color3 {
    background: #795548 !important;
  }

  .bg-color4 {
    background: #9e9e9e !important;
  }

  .bg-color5 {
    background: #0d47a1 !important;
  }

  .bg-cyan {
    background: #27a9e3 !important;
  }

  .bg-green {
    background: #28b779 !important;
  }

  .bg-orange {
    background: #da542e !important;
  }

  .bg-blue {
    background: #2255a4 !important;
  }

  .statics-card {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;

    h3,
    h1,
    h2,
    h4,
    h5,
    h6,
    svg,
    p {
      color: #fff !important;
    }

    svg {
      display: block;
      margin: 10px auto;
    }
  }

  .admin-card {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    border: 0px solid transparent;
    border-radius: 5px;
    margin-bottom: 20px;
    padding: 10px;
    box-shadow: 3px 2px 6px -1px rgba(0, 0, 0, 0.1);

    &.pd {
      padding: 30px;
    }
  }
}

.sub-category-td {
  td {
    padding-left: 50px !important;
    background: #fbfbfb;
  }
}

.time-from-to {
  margin-bottom: 20px;
  margin-top: 20px;
}
.times-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.available-times {
  .one-time {
    background: #f5f5f5;
    border-radius: 3px;
    padding: 12px;
    display: flex;
    align-items: center;
  }
}

.image-preview-image {
  background-color: #fff;
  border: 1px solid #ccc;
  overflow: hidden;
  img {
    width: 100%;
  }
}

.remove {
  background-color: red;
  border: none;
  width: 35px;
  color: #fff;
  height: 35px;
  transform: translateY(-10px);
}

.minus-degree {
  background-color: #eeeeee;
  color: #1b1b1b;
  padding: 3px;
}
.table.mistakes {
  th {
    text-align: right !important;
  }
  .question-text {
    width: 530px;
    font-size: 13px;
  }
}
.all-mistakes {
  display: flex;
  flex-wrap: wrap;
  .mistake {
    text-align: right;
    background-color: #ffe7d2;
    margin: 0px 3px 7px 3px;
    padding: 2px 10px;
    border-radius: 3px;
  }
}

//  print styles
@media print {
  @page {
    size: portrait;
    margin: 1ch;
  }
  .admin-sidebar {
    display: none !important;
  }
  .admin-card {
    padding: 0px !important;
    margin-bottom: 10px !important;
  }
  .admin-title {
    margin-bottom: 5px !important;
  }
  .admin-wrapper {
    padding-top: 0px !important;
    margin-right: 0px !important;
  }
}
.signatures {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.test-info {
  table {
    width: 100%;
  }
  table.half {
    width: 48%;
  }
  .two-tables {
    display: inline-block;
  }
  th {
    color: #2dae60 !important;
  }
  .th-print {
    width: 15%;
  }
  .td-sm {
    width: 5%;
  }
  .td-md {
    width: 25%;
  }
  .td-lg {
    width: 70%;
  }
  .td-print {
    width: 50%;
  }
  table,
  th,
  td {
    text-align: right;
    border: 1px solid rgb(195, 195, 195);
    // border-collapse: collapse;
  }
  th,
  td {
    padding: 5px;
  }
}

.tajweed-parent {
  font-weight: bold;
  font-size: 18px;
}

.d-flex {
  display: flex;
}

.ml-2 {
  margin-left: 20px;
}

.select-student .ant-select-selector {
  height: 40px !important;
}
